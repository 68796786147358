<template>
  <div class="navbar navbar-expand-lg navbar-light">
    <div class="text-center d-lg-none w-100">
      <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
      <i class="icon-unfold mr-2"></i>
      Footer
      </button>
    </div>
    <div class="navbar-collapse collapse" id="navbar-footer">
      <span class="navbar-text">
			&copy; 2023. <a href="#" class="font-weight-semibold"> RS Harapan Magelang. All Rights Reserved.</a> supported by <a href="https://lingkar9.com" target="_blank">Lingkar9</a>
		</span>
    
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>