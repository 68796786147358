import _ from 'lodash'

const menu = [
  {
    cap: 'Main',
    relate: [
      'Dashboard',
      'KeselamatanPasien',
      'RegistrasiPasien',
      'Hais',
      'LaporanInsiden',
      'Dokter',
      'Pasien',
      'LaporanManagement'
    ]
  },
  {
    title: 'Dashboard',
    route: {name: 'Dashboard'},
    menu: 'Dashboard',
    icon: 'icon-home4',
  },
  {
    title: 'Laporan Management',
    route: {
      name: 'LaporanManagement'
    },
    menu: 'LaporanManagement',
    icon: 'icon-file-text',
  },
  {
    title: 'Resiko Jatuh',
    route: {name: 'KeselamatanPasien'},
    menu: 'KeselamatanPasien',
    icon: 'icon-shield2',
  },
  {
    title: 'Registrasi Pasien',
    route: {
      name: 'RegistrasiPasien'
    },
    menu: 'RegistrasiPasien',
    icon: 'icon-list',
  },
  {
    title: 'HAIs',
    route: {name: 'Hais'},
    menu: 'Hais',
    icon: 'icon-notification2',
  },
  {
    title: 'Laporan Insiden',
    route: {name: 'LaporanInsiden'},
    menu: 'LaporanInsiden',
    icon: 'icon-file-text3',
  },
  {
    title: 'Dokter',
    route: {name: 'Dokter'},
    menu: 'Dokter',
    icon: 'icon-briefcase',
  },
  {
    title: 'Pasien',
    route: {name: 'Pasien'},
    menu: 'Pasien',
    icon: 'icon-users',
  },

  // pendaftaran
  {
    cap: 'Admisi',
    relate: [
      'RoPasien',
      'RoReservasi',
      'RoRujukan',
      'RoJadwalDokter',
      'RoAdmisiAntrean'
    ]
  },
  {
    title: 'Antrean',
    route: {
      name: 'RoAdmisiAntrean'
    },
    menu: 'RoAdmisiAntrean',
    icon: 'icon-list',
  },
  {
    title: 'Pasien',
    route: {
      name: 'RoPasien'
    },
    menu: 'RoPasien',
    icon: 'icon-users',
  },
  {
    title: 'Pendaftaran Reservasi',
    route: {
      name: 'RoReservasi'
    },
    menu: 'RoReservasi',
    icon: 'icon-bookmarks',
  },
  {
    title: 'Rujukan',
    route: {
      name: 'RoRujukan'
    },
    menu: 'RoRujukan',
    icon: 'icon-file-text3',
  }, {
    title: 'JadwalDokter',
    route: {
      name: 'RoJadwalDokter'
    },
    menu: 'RoJadwalDokter',
    icon: 'icon-calendar',
  }, 
  {
    cap: 'Billing',
    relate: [
      'RoBilling',
    ]
  }, 
  {
    title: 'Billing',
    route: {
      name: 'RoBilling'
    },
    menu: 'RoBilling',
    icon: 'icon-list',
  },

  {
    cap: 'Antrean',
    relate: [
      'AntreanRoAudio',
      'AntreanRoUnit',
      'AntreanRoGroup',
      'AntreanRoDisplayLayar',
      'AntreanRoSettingLayar',
    ]
  },
  {
    title: 'Antrean',
    icon: 'icon-list',
    children: [
      {
        title: 'Audio Antrean',
        route: { name: 'AntreanRoAudio' },
        menu: 'AntreanRoAudio'
      },
      {
        title: 'Unit Tujuan',
        route: { name: 'AntreanRoUnit' },
        menu: 'AntreanRoUnit'
      },
      {
        title: 'Group Antrian',
        route: { name: 'AntreanRoGroup' },
        menu: 'AntreanRoGroup'
      },
      {
        title: 'Display Layar',
        route: { name: 'AntreanRoDisplayLayar' },
        menu: 'AntreanRoDisplayLayar'
      },
      {
        title: 'Setting Layar',
        route: { name: 'AntreanRoSettingLayar' },
        menu: 'AntreanRoSettingLayar'
      },
      
    ]
  },
  // pendaftaran



  {
    cap: 'Other',
    relate: [
      'Users',
      'UserLevel',
      'MAgama',
      'MPekerjaan',
      'MPendidikan',
      'MKategoriSDKI',
      'MSubKategoriSDKI',
      'MSDKI',
      'MSLKI',
      'MKriteriaSLKI',
      'MSIKI',
      'MAlatBantuTarik',
      'MTindakan',
      'MCaraPembayaran',
      'MICD9',
      'MPoli',
      'MObat',
      'MAlatKesehatan',
      'MRadiologi',
      'MZipCode',
      // 'AppConfig',
      'MDosis',
      'MSubLab',
      'MLab',
      'MInsiden',
      'MRoFakesRujukan',
      'MRoPoliRujukan',
      'MRoDokterRujukan'
    ]
  },
  {
    title: 'Master',
    icon: 'icon-list',
    children: [
      {
        title: 'Aturan Pakai',
        route: { name: 'MDosis' },
        menu: 'MDosis'
      },
      {
        title: 'HAIs',
        route: { name: 'MHais' },
        menu: 'MHais'
      },
      {
        title: 'Kesadaran',
        route: { name: 'MKesadaran' },
        menu: 'MKesadaran'
      },
      {
        title: 'Poli',
        route: { name: 'MPoli' },
        menu: 'MPoli'
      },
      {
        title: 'Obat',
        route: { name: 'MObat' },
        menu: 'MObat'
      },
      {
        title: 'Alat Kesehatan',
        route: { name: 'MAlatKesehatan' },
        menu: 'MAlatKesehatan'
      },
      {
        title: 'Radiologi',
        route: { name: 'MRadiologi' },
        menu: 'MRadiologi'
      },
      {
        title: 'Alat Bantu Tarik',
        route: { name: 'MAlatBantuTarik' },
        menu: 'MAlatBantuTarik'
      },
      {
        title: 'Tindakan Fisioterapi',
        route: { name: 'MTindakan' },
        menu: 'MTindakan'
      },
      {
        title: 'Cara Pembayaran',
        route: { name: 'MCaraPembayaran' },
        menu: 'MCaraPembayaran'
      },
      {
        title: 'Agama',
        route: { name: 'MAgama' },
        menu: 'MAgama'
      },
      {
        title: 'Pekerjaan',
        route: { name: 'MPekerjaan' },
        menu: 'MPekerjaan'
      },
      {
        title: 'Pendidikan',
        route: { name: 'MPendidikan' },
        menu: 'MPendidikan'
      },
      {
        title: 'SDKI',
        children: [
        {
          title: 'Kategori',
          route: {
            name: 'MKategoriSDKI'
          },
          menu: 'MKategoriSDKI'
        },
        {
          title: 'Sub Kategori',
          route: {
            name: 'MSubKategoriSDKI'
          },
          menu: 'MSubKategoriSDKI'
        },
        {
          title: 'Diagnosa',
          route: {
            name: 'MSDKI'
          },
          menu: 'MSDKI'
        }]
      },
      {
        title: 'SLKI',
        children: [
          {
            title: 'Diagnosa',
            route: {
              name: 'MSLKI'
            },
            menu: 'MSLKI'
          },
          {
            title: 'Kriteria Hasil',
            route: {
              name: 'MKriteriaSLKI'
            },
            menu: 'MKriteriaSLKI'
          }
        ]
      },
      {
        title: 'SIKI',
        route: { name: 'MSIKI' },
        menu: 'MSIKI'
      },
      {
        title: 'Diagnosa Gizi',
        route: { name: 'MICDGizi' },
        menu: 'MICDGizi'
      },
      {
        title: 'Intervensi Gizi',
        route: {
          name: 'MICDGiziTindakan'
        },
        menu: 'MICDGiziTindakan'
      },
      {
        title: 'ICD9',
        route: { name: 'MICD9' },
        menu: 'MICD9'
      },
      {
        title: 'ICD10',
        route: { name: 'MICD10' },
        menu: 'MICD10'
      },
      {
        title: 'Insiden',
        route: { name: 'MInsiden' },
        menu: 'MInsiden'
      },
      {
        title: 'Zip Code',
        route: { name: 'MZipCode' },
        menu: 'MZipCode'
      },
      {
        title: 'Laboratorium',
        children: [
        {
          title: 'Sub Kategori',
          route: {
            name: 'MSubLab'
          },
          menu: 'MSubLab'
        },
        {
          title: 'Tindakan',
          route: {
            name: 'MLab'
          },
          menu: 'MLab'
        }]
      },
      {
        title: 'Zip Code',
        route: { name: 'MZipCode' },
        menu: 'MZipCode'
      },
      {
        title: 'Fakes Rujukan',
        children: [{
            title: 'Nama Fakes',
            route: {
              name: 'MRoFakesRujukan'
            },
            menu: 'MRoFakesRujukan'
          },
          {
            title: 'Poli',
            route: {
              name: 'MRoPoliRujukan'
            },
            menu: 'MRoPoliRujukan'
          },
          {
            title: 'Dokter',
            route: {
              name: 'MRoDokterRujukan'
            },
            menu: 'MRoDokterRujukan'
          },
        ]
      },
      {
        title: 'Suku',
        route: { name: 'MRoSuku' },
        menu: 'MRoSuku'
      },
      {
        title: 'Riwayat Alergi',
        route: { name: 'MRiwayatAlergi' },
        menu: 'MRiwayatAlergi'
      },
    ],
  },
  {
    title: 'User Management',
    icon: 'icon-users',
    children: [
      {
        title: 'User List',
        route: {name: 'Users'},
        menu: 'Users'
      },
      {
        title: 'User Level',
        route: {name: 'UserLevel'},
        menu: 'UserLevel'
      },
    ],
  },
  {
    title: 'Audit Trail',
    route: {
      name: 'AuditTrail'
    },
    icon: 'icon-check',
    menu: 'AuditTrail'
  },
  

  // pendaftaran online
  {
    cap: 'BPJS',
    relate: [
      'BPJSPeserta',
      'BPJSReferensi',
      'BPJSMonitoring',
      'BPJSSEP',
      'BPJSPRB',
      'BPJSRencanaKontrol',
      'BPJSDataSEP',
      'BPJSLPK',
    ]
  },
  {
    title: 'BPJS VCLAIM',
    icon: 'icon-list',
    children: [
      {
        title: 'Peserta',
        route: {
          name: 'BPJSPeserta'
        },
        menu: 'BPJSPeserta'
      },
      {
        title: 'Referensi',
        route: {
          name: 'BPJSReferensi'
        },
        menu: 'BPJSReferensi'
      },
      {
        title: 'Monitoring',
        route: {
          name: 'BPJSMonitoring'
        },
        menu: 'BPJSMonitoring'
      },
      {
        title: 'CEK SEP',
        route: {
          name: 'BPJSSEP'
        },
        menu: 'BPJSSEP'
      },
      {
        title: 'Rujukan',
        route: {
          name: 'BPJSRujukan'
        },
        menu: 'BPJSRujukan'
      },
      {
        title: 'PRB',
        route: {
          name: 'BPJSPRB'
        },
        menu: 'BPJSPRB'
      },
      {
        title: 'Rencana Kontrol',
        route: {
          name: 'BPJSRencanaKontrol'
        },
        menu: 'BPJSRencanaKontrol'
      },
      {
        title: 'Data SEP',
        route: {
          name: 'BPJSDataSEP'
        },
        menu: 'BPJSDataSEP'
      },
      {
        title: 'Data LPK',
        route: {
          name: 'BPJSLPK'
        },
        menu: 'BPJSLPK'
      }
    ],
  },
  {
    title: 'BPJS Antrean',
    icon: 'icon-list',
    children: [
      {
        title: 'Referensi Antrean',
        route: {
          name: 'BPJSRefrensiAntrean'
        },
        menu: 'BPJSRefrensiAntrean'
      }
    ],
  },
  // pendaftaran online


  {
    cap: ' '
  },
  {
    cap: ' '
  },
]

let nav = menu
const accessAll = localStorage.getItem('u_menu_all')
if (accessAll == 'N') {
  let menuRole = JSON.parse(localStorage.getItem('u_menu') || '[]')
  menuRole = _.map(menuRole, v => {
    return v.menu
  })
  
  nav = _.filter(nav, v => {
    if (v.cap) {
      return _.filter(v.relate, v2 => {
        return menuRole.indexOf(v2) > -1
      }).length >= 1
    } 
    else if (v.children) {
      return _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      }).length >= 1
    } 
    else {
      return menuRole.indexOf(v.menu) > -1
    }
  })

  nav = _.map(nav, v => {
    if (v.children) {
      v.children = _.filter(v.children, v2 => {
        if((v2.children||[]).length){
          v2.children = _.filter(v2.children, v3 => {
            return menuRole.indexOf(v3.menu) > -1
          })
          if ((v2.children||[]).length) {
            return v2
          }
        }else{
          return menuRole.indexOf(v2.menu) > -1
        }
      })
    }
    return v
  })
}

export default nav